import axios from "axios";
import { Message } from 'element-ui';


const instance = axios.create({
    baseURL: "http://124.222.71.57",
    timeout: 30000
});

instance.interceptors.request.use(config => {
    return config
}, err => {
    return Promise.reject(err)
})

instance.interceptors.response.use(res => {

    return res.data

}, err => {
    if (err.code === 'ECONNABORTED') {
        // console.log(err);
        // Message.error('网络请求超时，服务器检索太慢，请尝试其他平台检索');
        // 显示错误提示
        // Message({
        //     message: '请求超时，请检查网络连接或稍后重试',
        //     type: 'error'
        // });
        return 'timeout';
    }
    return Promise.reject(err)
})

export default instance