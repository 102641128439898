import Vue from 'vue'
import { Button,Input,FormItem,Form,Radio,RadioGroup,Card,Message,Pagination,Row,Col,Image } from 'element-ui'

Vue.prototype.$message = Message;

Vue.use(Button)
Vue.use(Input)
Vue.use(FormItem)
Vue.use(Form)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Card)
Vue.use(Pagination)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)

