<template>
    <div class="home-page">
        <h1>图书信息检索</h1>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" @submit.native.prevent
            @keyup.enter.native="search('ruleForm')">
            <div class="user-input">
                <el-form-item class="search-input" prop="input1" :show-message="false">
                    <!-- <el-input v-model="ruleForm.input1" type="text" placeholder="请输入关键词（书名、作者、ISBN、SSID等）；疑难查询只支持ISBN查询"
                        clearable></el-input> -->
                    <el-input v-model="ruleForm.input1" type="text" placeholder="请输入关键词（书名、作者、ISBN、出版社等）"
                        clearable></el-input>
                </el-form-item>

                <el-form-item class="search-btn">
                    <el-button type="primary" icon="el-icon-search" @click="search('ruleForm')">搜索</el-button>
                </el-form-item>

                <!-- <el-form-item class="search-btn2">
                    <el-button type="primary" icon="el-icon-search" @click="search2('ruleForm')">疑难查询</el-button>
                </el-form-item> -->
            </div>

            <div>
                <el-radio-group class="choice" v-model="radio">
                    <el-radio :label="1">全部字段</el-radio>
                    <el-radio :label="4">ISBN</el-radio>
                    <el-radio :label="2">书名</el-radio>
                    <el-radio :label="3">作者</el-radio>
                </el-radio-group>
            </div>

        </el-form>

        <div class="notice" v-show="isShow">
            <el-card class="box-card">
                <div slot="header" class="clearfix">

                    <span class="title"><i class="el-icon-info"></i> 通知公告</span>

                </div>
                <div class="text item">搜索结果最多只展示<span class="imText">500</span>条，请尽量用精准关键词检索</div>
                <div class="text item">是从<span class="imText">离线数据库</span>检索，并非全网爬虫</div>
                <div class="text item">检索结果中显示的封面来自<span class="imText">自有图库</span>，并非爬虫其他网站</div>
                <div class="text item">本检索系统仅供个人学习使用，<span class="imText">只有查询功能</span>，仅用于检索书籍详细信息，请勿用于其他非法用途，否则后果自负
                </div>
                <div class="text item">若后台发现有不文明使用（网络攻击、爬虫采集等）或用于非法用途的，作者有权停止提供服务并加入IP黑名单（进黑名单后也不能使用其他软件）</div>
                <div class="text item">可<span class="imText">定制</span>类似的查询系统或者其他网页，微信：<span
                        class="imText">xiaoe1588</span>，QQ：<span class="imText">1801718263</span></div>


            </el-card>
        </div>

        <div class="result">
            <!-- <h3 class="result-title">检索结果：</h3> -->
            <h2 v-show="isShow3" style="text-align: left;margin-bottom:20px">{{ resinfo }}</h2>
            <el-card v-show="isShow2" shadow="hover" class="result-card" v-for="(item, index) in bookData" :key="index">
                <el-image :src="item.img" alt="" lazy class="img"></el-image>
                <div class="book">
                    <!-- <div class="bookname">{{ item.bookname }}</div> -->
                    <div class="bookname" v-html="item.bookname"></div>

                    <div class="bookinfo">
                        <span class="info">作者：</span>{{ item.author }}
                    </div>
                    <div class="bookinfo">
                        <span class="info">出版社：</span>{{ item.press }}
                    </div>
                    <div class="bookinfo">
                        <span class="info">年份：</span>{{ item.year }}
                    </div>
                    <div class="bookinfo">
                        <span class="info">页数：</span>{{ item.pages }}
                    </div>
                    <div class="bookinfo">
                        <span class="info">ISBN：</span>{{ item.isbn }}
                    </div>
                    <div class="bookinfo">
                        <span class="info">SS：</span>{{ item.ss }}
                    </div>
                    <div class="bookinfo">
                        <span class="info">库存：</span>{{ item.ku }}
                    </div>

                    <el-button type="primary" @click="copySS(index)" class="copy-btn">复制SS</el-button>
                    <el-button type="primary" @click="copyISBN(index)" class="copy-btn">复制ISBN</el-button>
                    <el-button type="primary" @click="copyInfo(index)" class="copy-btn">复制信息</el-button>

                    <el-button type="primary" @click="call" class="copy-btn">点击咨询</el-button>
                </div>
            </el-card>

            <el-pagination v-show="isShow4" class="page" background layout="prev, pager, next" :total="allNum"
                @current-change="handelPage">
            </el-pagination>
        </div>

    </div>
</template>

<script>
// @ is an alias to /src
import clipboard from 'clipboard'
import { validateInput } from "@/utils/validate"
import { SearchApi } from "@/request/api"
import { SearchApi2 } from "@/request/api"

export default {
    name: 'HomeView',
    components: {

    },
    data() {
        return {
            ruleForm: {
                input1: "信息安全",
            },
            rules: {
                input1: [
                    {
                        required: true,
                        message: "关键词不能为空",
                    },
                    {
                        validator: validateInput,
                        trigger: 'blur'
                    }
                ]
            },
            radio: 1,
            isShow: false,
            isShow2: false, // 结果
            resinfo: '当前检索无结果',
            isShow3: false, //结果提示
            isShow4: false,
            allNum: 1,
            allData: [],
            bookData: [
                {
                    img: 'http://api.xiaoesoft.top/cover?ss=13001645&key=37ec11e6ff907025a6de6ffb74b4316f',
                    bookname: '信息安全导论',
                    author: '何泾沙主编；何泾沙，韦潜等编著',
                    press: '北京：机械工业出版社',
                    year: '2012',
                    pages: '224',
                    isbn: '9787111362722',
                    ss: '13001645',
                    ku: '有'
                }
            ]
        }
    },
    methods: {
        search(formName) {
            console.log(this.ruleForm.input1);
            // console.log(this.input1.trim());
            console.log(this.radio);
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.isShow = false;
                    this.bookData = [];

                    // 请求
                    this.resinfo = '检索中...';
                    this.isShow3 = true;
                    this.isShow4 = false;
                    let params = {
                        q: this.ruleForm.input1,
                        f: this.radio
                    }
                    let res = await SearchApi({ params })

                    if (!res) return;

                    if (res == 'timeout') {
                        this.$message({
                            message: '网络请求超时，服务器检索太慢，请尝试通过其他平台检索',
                            type: 'error',
                            duration: 5000
                        });
                        this.resinfo = '网络请求超时，服务器检索太慢，请尝试通过其他平台检索';
                    } else if (res.state == 2) {
                        this.resinfo = '今日查询次数已达上限，请明天再试';
                        this.$message.error('今日查询次数已达上限，请明天再试');
                    } else if (res.state == 1) {
                        this.resinfo = '当前检索无结果'
                        // this.isShow3 = true;
                        this.isShow4 = false;
                    } else if (res.state == 3) {
                        this.resinfo = '没有访问权限，请联系管理员';
                        this.$message.error('没有访问权限，请联系管理员');
                    } else {
                        this.allNum = res.num;
                        this.resinfo = `共${this.allNum}条，耗时${res.sec}秒`;
                        // this.isShow3 = true;
                        this.allData = res.data;

                        this.bookData = this.allData.slice(0, 10)

                        const keywords = this.ruleForm.input1.trim().toLowerCase().split(/\s+/); // 分割关键词，支持空格分隔
                        keywords.forEach(keyword => {
                            this.bookData.forEach(item => {
                                if (!item.bookname.toLowerCase().includes(keyword)) return;
                                let hightStr = `<span style="color:red;">${keyword}</span>`;
                                let str = new RegExp(keyword, 'gi');
                                item.bookname = item.bookname.replace(str, hightStr);
                            });
                        });
                        this.isShow2 = true;
                        this.isShow4 = true;
                    }


                } else {
                    this.$message({
                        message: '请输入正确的关键词后再提交',
                        type: 'warning'
                    });
                    return false;
                }
            });


        },
        search2(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.isShow = false;
                    this.bookData = [];
                    // 请求
                    this.resinfo = '检索中...';
                    this.isShow3 = true;
                    this.isShow4 = false;
                    let params = {
                        q: this.ruleForm.input1,
                        f: '6'
                    }
                    let res = await SearchApi2({ params })
                    if (!res) return;

                    if (res == 'timeout') {
                        this.$message({
                            message: '网络请求超时，服务器检索太慢，请尝试通过其他平台检索',
                            type: 'error',
                            duration: 5000
                        });
                        this.resinfo = '网络请求超时，服务器检索太慢，请尝试通过其他平台检索';
                    } else if (res.state == 2) {
                        this.resinfo = '今日查询次数已达上限，请明天再试';
                        this.$message.error('今日查询次数已达上限，请明天再试');
                    } else if (res.state == 1) {
                        this.resinfo = this.ruleForm.input1 + ' 疑难检索结果：无'
                        // this.isShow3 = true;
                        this.isShow4 = false;
                    } else {
                        this.resinfo = this.ruleForm.input1 + ' 疑难检索结果：有，请联系：微信：vip6655880'
                        // this.isShow3 = true;
                        this.isShow4 = false;
                    }
                } else {
                    this.$message({
                        message: '请输入正确的关键词后再提交',
                        type: 'warning'
                    });
                    return false;
                }
            });
        },
        handelPage(val) {
            console.log(val);
            this.bookData = this.allData.slice(10 * (val - 1), 10 * val);
            const keywords = this.ruleForm.input1.trim().toLowerCase().split(/\s+/); // 分割关键词，支持空格分隔
            keywords.forEach(keyword => {
                this.bookData.forEach(item => {
                    if (!item.bookname.toLowerCase().includes(keyword)) return;
                    let hightStr = `<span style="color:red;">${keyword}</span>`;
                    let str = new RegExp(keyword, 'gi');
                    item.bookname = item.bookname.replace(str, hightStr);
                });
            });
        },
        copySS(index) {
            let ssid = this.bookData[index].ss;
            clipboard.copy(ssid);
            this.$message.success('已复制SS到剪贴板');
        },
        copyISBN(index) {
            let isbn = this.bookData[index].isbn;
            clipboard.copy(isbn);
            this.$message.success('已复制ISBN到剪贴板');
        },
        copyInfo(index) {
            let bookinfo = this.bookData[index]
            console.log(bookinfo);
            let text = '书名：' + bookinfo.bookname.replace(/<\/?span[^>]*>/g, '') + '\n' + '作者：' + bookinfo.author + '\n' + '出版社：' + bookinfo.press + '\n' + '年份：' + bookinfo.year + '\n' + '页数：' + bookinfo.pages + '\n' + 'ISBN：' + bookinfo.isbn + '\n' + 'SS：' + bookinfo.ss
            clipboard.copy(text);
            console.log('复制成功');

            this.$message.success('已复制详细信息到剪贴板');
        },

        call() {
            window.open('', '_blank')
        }
    },
    created() {
        this.ruleForm.input1 = '';
    }
}
</script>

<style lang="less" scoped>
.home-page {
    width: 60%;
    margin: 50px auto;
    position: relative;

    h1 {
        margin: 50px;
        font-size: 30px;
    }

    .user-input {
        width: 80%;
        left: 10%;
        position: relative;
        display: flex;

        .search-input {
            flex: 1;
        }

        .search-btn2 {
            margin-left: 10px;
        }

    }

    .choice {
        position: relative;
        float: left;
        left: 10%;
    }

    .box-card {
        position: absolute;
        width: 100%;
        height: auto;
        left: 50%;
        transform: translate(-50%, 20%);

        .clearfix:before,
        .clearfix:after {
            display: table;
            content: "";
        }

        .clearfix:after {
            clear: both
        }

        .title {
            font-size: 20px;
            float: left;
            font-weight: bold;
            margin-top: 5px;
        }

        .text {
            font-size: 17px;
            clear: both;
            float: left;
            text-align: left;
            line-height: 20px;
        }

        .item {
            margin-bottom: 30px;
        }

        .imText {
            color: blue;
        }

    }

    .result {
        position: absolute;
        transform: translate(-50%, 100%);
        width: 100%;
        height: 120px;
        left: 50%;

        .result-card {
            text-align: left;
            margin-bottom: 10px;

            .img {
                float: left;
                margin-right: 10px;
                margin-bottom: 20px;
            }

            .book {
                overflow: hidden;
                /* 描述文字盒子包裹在图片右边 */
                margin-bottom: 10px;

                .bookname {
                    font-weight: 600;
                    color: #261cdc;
                    font-size: 20px;
                    margin-top: 5px;
                    margin-bottom: 15px;
                }

                .bookinfo {
                    margin-top: 5px;

                    .info {
                        color: #0f8549;
                        font-weight: bold;
                        font-size: 14px;
                    }
                }

                .copy-btn {
                    margin-top: 15px;
                }
            }


        }

        .result-card:hover {
            box-shadow: 5px 5px 20px #3caa73;

        }

        .el-pagination {
            //margin-bottom: 50px;
            height: 100px;
        }

    }

}
</style>